import React from "react";
import Headermask from "./Headermask";
import Navbar from "./Navbar";
import TinySlider from "tiny-slider-react";
import "tiny-slider/dist/tiny-slider.css";

const headsett = {
  items: 1,
  lazyload: true,
  nav: false,
  mouseDrag: true,
  controls: false,
  autoplay: true,
  autoplayButtonOutput: false,
};

const Header = () => {
  return (
    <div className="w-full">
      <div className="w-full bg-white shadow-md shadow-stone-200 h-16 fixed z-50">
        <Navbar />
      </div>
      <div className="relative h-96 md:h-auto lg:h-auto">
        <div className="absolute top-32 w-3/4 left-1/2 -translate-x-1/2 md:top-1/3 md:w-1/2 h-32 z-10">
          <TinySlider settings={headsett}>
            <div key={1} className="px-6 w-1/2 relative">
              <p className="text-white italic text-2xl md:text-6xl text-center">
                Apoyamos a nuestros emprendedores en la distribución de sus
                productos.
              </p>
            </div>
            <div key={2} className="px-6 w-1/2 relative">
              <p className="text-white italic text-2xl md:text-6xl text-center">
                Trabajamos con la mayor seguridad, puntualidad y eficiencia.
              </p>
            </div>
            <div key={3} className="px-6 w-1/2 relative">
              <p className="text-white italic text-2xl md:text-6xl text-center">
                El socio estratégico en transporte, logística y distribución.
              </p>
            </div>
          </TinySlider>
        </div>
        <div className="relative h-96 md:h-auto lg:h-auto">
          <div className="absolute -bottom-1 left-0 w-full z-10">
            <Headermask className="w-full" />
          </div>
          <div className="w-full h-full bg-gradient-to-r from-sky-400 to-indigo-900">
            <img
              src={require("../assets/header_bg.jpg")}
              alt="header"
              className="w-full h-full object-cover opacity-60"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
