import React from "react";

const ServicioItem = ({ datos }) => {
  return (
    <div className="border border-stone-100 shadow-md bg-white p-2 flex md:flex lg:block">
      <div className="w-1/4 lg:w-full">
        <figure className="bg-sky-500 mr-2 md:mr-3 lg:mr-0 h-full">
          <div className="flex items-center justify-between h-full">
            <img
              src={require("../assets/" + datos.imagen)}
              alt={datos.titulo}
              className="w-full aspect-square my-auto"
            />
          </div>
        </figure>
      </div>
      <div className="w-3/4 lg:w-full">
        <h3 className="text-stone-600 text-3xl py-2 md:text-xl">
          {datos.titulo}
        </h3>
        <p className="text-stone-600 text-sm text-justify">
          {datos.descripcion}
        </p>
      </div>
    </div>
  );
};

export default ServicioItem;
