import React from "react";
import ServicioItem from "./ServicioItem";
const serviciosLista = [
  {
    imagen: "primera_milla.webp",
    titulo: "Primera milla",
    descripcion:
      "Apoyamos en la distribución de primera línea, trasladando los productos desde los centros de distribución hacia los canales de ventas más importantes del país.",
  },
  {
    imagen: "larga_distancia.webp",
    titulo: "Larga distancia",
    descripcion:
      "Recogemos tus contenedores y los movilizamos hacia los centros de distribución a lo largo de todo Chile y Latinoamérica con la mayor seguridad, puntualidad y eficiencia.",
  },
  {
    imagen: "ultima_milla.webp",
    titulo: "Última milla",
    descripcion:
      "Trasladamos las compras del ecommerce y retailers más grandes del país a la puerta de tu casa, con la mayor dedicación y cuidado para que el consumidor final reciba el producto con la calidad esperada.",
  },
  {
    imagen: "apoyo_pymes.webp",
    titulo: "Apoyo pymes",
    descripcion:
      "Apoyamos a nuestros emprendedores en la distribución de sus productos de manera rápida, entregando el mismo amor y dedicación en cada entrega.",
  },
];

const Servicios = () => {
  return (
    <div className="w-full py-12">
      <div className="max-w-5xl mx-auto px-4">
        <div
          className="text-xs text-sky-600 py-1 px-2 rounded-md bg-sky-100 max-w-max scroll-mt-20"
          id="servicios"
        >
          Nos hacemos cargo
        </div>
        <h2 className="text-stone-500 text-4xl mb-2">Servicios</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-3">
          {serviciosLista.map((servicio, index) => (
            <ServicioItem datos={servicio} key={index} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Servicios;
