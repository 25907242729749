import React from "react";
import empresa from "../assets/empresa.jpg";

const Empresa = () => {
  return (
    <div className="w-full py-12">
      <div className="max-w-5xl mx-auto px-4">
        <div className="w-full md:flex lg:flex">
          <div className="w-full md:w-1/2 px-2">
            <div
              className="text-xs text-sky-600 py-1 px-2 rounded-md bg-sky-100 max-w-max scroll-mt-20"
              id="empresa"
            >
              ¿Quiénes somos?
            </div>
            <h2 className="text-stone-500 text-4xl mb-2">Empresa</h2>
            <p className="text-justify text-sm">
              Somos el socio estratégico de importantes compañías en el rubro
              del transporte, logística y distribución. Nos preocupamos de
              entregar un servicio de calidad, atendiendo las necesidades de
              nuestros clientes, quienes depositan su confianza en nosotros.
              Nuestro objetivo es apoyar y crecer en conjunto, brindando
              seguridad, responsabilidad y confiabilidad en el manejo de su
              carga. Trabajar con nosotros es la mejor opción, el prestigio de
              tu empresa, en nuestras manos es la principal preocupación de
              Transantino. Somos el mejor partner logístico del país.
            </p>
          </div>
          <div className="w-full md:w-1/2 px-2">
            <figure className="w-full">
              <img src={empresa} alt="Empresa" />
            </figure>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Empresa;
