import React from "react";
import { Disclosure } from "@headlessui/react";
import { Menu, X } from "react-feather";
import Logo from "./Logo";

const navigation = [
  { name: "Empresa", href: "#empresa", current: false },
  { name: "Servicios", href: "#servicios", current: false },
  { name: "Transportistas", href: "#transportistas", current: false },
  { name: "Contacto", href: "#contacto", current: false },
];

const Navbar = () => {
  return (
    <Disclosure as="nav" className="!bg-transparent">
      {({ open }) => (
        <>
          <div className="max-w-5xl mx-auto px-2 sm:px-6 lg:px-8">
            <div className="w-full relative flex items-center justify-between h-16 ">
              <div className="absolute inset-y-0 left-0 flex items-center sm:hidden w-full">
                {/* Mobile menu button*/}
                <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-stone-600 hover:text-white hover:bg-sky-300 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                  <span className="sr-only">Abrir main menu</span>
                  {open ? (
                    <X className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <Menu className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
                <div className="w-3/4 text-center text-stone-600">
                  <Logo
                    className="w-1/2 mx-auto"
                    key="mobile"
                    id="logomobile"
                    data={"mobile"}
                  />
                </div>
              </div>
              <div className="mx-auto hidden sm:flex w-full">
                <div className="w-1/5 text-center text-stone-600">
                  <Logo className="w-4/5" key="web" id="logoweb" data={"web"} />
                </div>
                {navigation.map((item) => (
                  <a
                    key={item.name}
                    href={item.href}
                    className={
                      "w-1/5 text-center text-stone-600 font-semibold hover:text-white hover:bg-sky-300 py-2"
                    }
                    aria-current={item.current ? "page" : undefined}
                  >
                    {item.name}
                  </a>
                ))}
              </div>
            </div>
          </div>

          <Disclosure.Panel className="sm:hidden">
            <div className="px-2 pt-2 pb-3 space-y-1 bg-sky-400">
              {navigation.map((item) => (
                <Disclosure.Button
                  key={item.name}
                  as="a"
                  href={item.href}
                  className={
                    "text-white hover:bg-sky-600 py-2 block px-3 rounded-md text-base font-medium"
                  }
                  aria-current={item.current ? "page" : undefined}
                >
                  {item.name}
                </Disclosure.Button>
              ))}
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
};

export default Navbar;
