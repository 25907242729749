import Header from "./components/Header";
import Empresa from "./components/Empresa";
import Servicios from "./components/Servicios";
import Exito from "./components/Exito";
import Transportistas from "./components/Transportistas";
import Contacto from "./components/Contacto";

function App() {
  return (
    <div className="w-full scroll-smooth">
      <Header />
      <Empresa />
      <Exito />
      <Servicios />
      <Transportistas />
      <Contacto />
    </div>
  );
}

export default App;
