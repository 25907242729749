import React from "react";
import TinySlider from "tiny-slider-react";
import "tiny-slider/dist/tiny-slider.css";

const settings = {
  lazyload: true,
  nav: false,
  mouseDrag: true,
  controls: false,
  autoplay: true,
  autoplayButtonOutput: false,
};

const Exito = () => {
  return (
    <div className="w-full py-12 bg-gradient-to-tr from-sky-500  to-indigo-900">
      <div className="max-w-5xl mx-auto px-4">
        <div className="text-xs text-sky-600 py-1 px-2 rounded-md bg-sky-100 max-w-max">
          Evaluación de nuestros clientes
        </div>
        <h2 className="text-stone-50 text-4xl mb-2">Casos de Éxito</h2>
        <div className="mx-auto w-full py-4">
          <TinySlider settings={settings}>
            <div key={1} style={{ position: "relative" }} className="px-6">
              <p className="text-stone-50 italic text-lg">
                Actualmente TRANSPORTES TRANSANTINO LTDA., Se presenta prestando
                servicios en las bases de Santiago, Viña del Mar y Melipilla.
                Teniendo buenas referencias de su proceso administrativo de
                operación y efectividad de entregas.
              </p>

              <p className="text-stone-50 italic text-lg">
                Recomiendo a esta empresa y su equipo de trabajo por su ética de
                trabajo como su compromiso a las labores diarias para
                desarrollarse de mejor forma.
              </p>

              <p className="text-stone-50 italic text-lg">
                Desde ya doy las gracias por su gran trabajo y espero que sigan
                siendo parte del equipo de Blue Express S.A. en los nuevos
                proyectos y los que se encuentran actualmente ejecutándose.
              </p>
            </div>
            <div key={2} style={{ position: "relative" }} className="px-6">
              <p className="text-stone-50 italic text-lg">
                TRANSPORTES TRANSANTINO LTDA., es una empresa constituida por
                personas orientadas al proceso logístico y gracias a ellos que
                han formado parte de nuestra familia en Blue Express S.A. hemos
                sorteado diferentes escenarios trascendentales en nuestro país.
              </p>

              <p className="text-stone-50 italic text-lg">
                Ingresaron a cumplir sus funciones con nuestra operación
                logística en el mes de abril de este año y desde ese momento
                hasta hoy en día, han cumplido todos los requerimientos
                efectuados por su dotación que actualmente trabaja con nosotros.
              </p>
            </div>
            <div key={3} style={{ position: "relative" }} className="px-6">
              <p className="text-stone-50 italic text-lg">Buenas tardes</p>

              <p className="text-stone-50 italic text-lg">
                Aprovecho esta oportunidad para reconocer y agradecer el full
                compromiso y apoyo que nos ha brindado Transantino Limitada en
                nuestro proceso de última milla SCL, la flexibilidad de tu
                flota, junto con la calidad de las dotaciones, nos ha permitido
                migrar unidades a nuestros distintos procesos de distribución
                tanto en Hub 1, LYD como en Hub 3.
              </p>

              <p className="text-stone-50 italic text-lg">
                Mis sinceras felicitaciones a equipo de Gerencias y Dotaciones
                Transantino Limitada por el excelente servicio que nos han
                prestado, esperamos como Blue Express, que esta relación de
                socio estratégico y comercial perdure.
              </p>

              <p className="text-stone-50 italic text-lg">Saludos cordiales.</p>
            </div>
          </TinySlider>
        </div>
      </div>
    </div>
  );
};

export default Exito;
