import React from "react";

function Headermask() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fillRule="evenodd"
      strokeLinejoin="round"
      strokeMiterlimit="2"
      clipRule="evenodd"
      viewBox="0 0 1336 149"
    >
      <path
        fill="url(#_Linear1)"
        d="M1315.17 400.31C785.811 615.148 344.466 286.323-20.4 462.99v77.04h1335.57V400.31z"
        transform="translate(20.4 -400.31)"
      ></path>
      <path
        fill="#fff"
        d="M1315.17 411.95C785.811 626.787 344.466 363.332-20.4 539.999v9.249h1335.57V411.95z"
        transform="translate(20.4 -400.31)"
      ></path>
      <defs>
        <linearGradient
          id="_Linear1"
          x1="0"
          x2="1"
          y1="0"
          y2="0"
          gradientTransform="matrix(1090.8 36.5817 -36.5817 1090.8 96.529 489.969)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#33F3FB"></stop>
          <stop offset="1" stopColor="#03B9E9"></stop>
        </linearGradient>
      </defs>
    </svg>
  );
}

export default Headermask;
