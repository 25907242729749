import React, { useState } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";

const Transportistas = () => {
  const { register, handleSubmit } = useForm();
  const [mensaje, setMensaje] = useState(false);
  const [deshabilitar, setDeshabilitar] = useState(false);
  const onSubmit = (data) => {
    setDeshabilitar(true);
    axios({
      method: "post",
      url: "https://72nyca51ti.execute-api.us-east-1.amazonaws.com/prod_trans",
      headers: { "content-type": "application/json" },
      data: data,
    })
      .then((result) => {
        //console.log(result);
        setMensaje(true);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <div className="w-full py-12">
      <div className="max-w-5xl mx-auto px-4">
        <div className="w-full md:flex lg:flex">
          <div className="w-full md:w-1/2 px-3">
            <div
              className="text-xs text-sky-600 py-1 px-2 rounded-md bg-sky-100 max-w-max scroll-mt-20"
              id="transportistas"
            >
              Comencemos a trabajar juntos
            </div>
            <h2 className="text-stone-500 text-4xl mb-2">
              Únete a nuestro equipo
            </h2>
            <p className="text-justify">
              Se parte de nuestro equipo y comienza a trabajar con nosotros.
            </p>

            <p className="text-justify">
              Nos preocupamos por nuestros conductores, para crear un equipo
              cercano y comprometido, brindando la seguridad en cada ruta.
            </p>
          </div>
          <div className="w-full md:w-1/2 px-3">
            {mensaje && (
              <div className="mt-1 text-xl text-sky-500 ">
                Mensaje enviado, nos pondremos en contacto con usted.
              </div>
            )}
            {!mensaje && (
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="py-2 my-2">
                  <label
                    htmlFor="nombre"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Nombre
                  </label>
                  <input
                    type="text"
                    id="nombre"
                    {...register("nombre", { required: true })}
                    className="mt-1 focus:ring-sky-500 focus:border-sky-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
                <div className="py-2 my-2">
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium text-gray-700"
                  >
                    E-mail
                  </label>
                  <input
                    type="email"
                    id="email"
                    {...register("email", {
                      required: true,
                      pattern:
                        /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+.)+[^<>()[\].,;:\s@"]{2,})$/i,
                    })}
                    className="mt-1 focus:ring-sky-500 focus:border-sky-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
                <div className="py-2 my-2">
                  <label
                    htmlFor="fono"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Teléfono
                  </label>
                  <input
                    type="text"
                    id="fono"
                    {...register("fono", { required: true })}
                    className="mt-1 focus:ring-sky-500 focus:border-sky-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
                <div className="py-2 my-2">
                  <button
                    type="submit"
                    disabled={deshabilitar}
                    className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-sky-600 hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500 disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none"
                  >
                    Quiero postular
                  </button>
                </div>
              </form>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Transportistas;
