import React, { useState } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";

const Contacto = () => {
  const { register, handleSubmit } = useForm();
  const [mensaje, setMensaje] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const onContact = (data) => {
    setDisabled(true);
    axios({
      method: "post",
      url: "https://y2cj4bdsmc.execute-api.us-east-1.amazonaws.com/prod",
      headers: { "content-type": "application/json" },
      data: data,
    })
      .then((result) => {
        //console.log(result);
        setMensaje(true);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <div className="w-full py-12 bg-stone-100">
      <div className="max-w-5xl mx-auto px-4">
        <div
          className="text-xs text-sky-600 py-1 px-2 rounded-md bg-sky-100 max-w-max scroll-mt-20"
          id="contacto"
        >
          Información del servicio
        </div>
        <h2 className="text-stone-500 text-4xl mb-2">Contacto</h2>
        <p className="text-justify">
          Utilice el siguiente formulario para contarnos brevemente del desafío
          que enfrenta.
        </p>
        <p className="text-justify">
          Así podemos ayudarle a encontrar la solución más adecuada para su
          negocio.
        </p>
        <div>
          {mensaje && (
            <div className="mt-1 text-xl text-sky-500 ">
              Mensaje enviado, pronto nos pondremos en contacto para que
              conversemos.
            </div>
          )}
          {!mensaje && (
            <form onSubmit={handleSubmit(onContact)}>
              <div className="grid grid-cols-6 gap-6">
                <div className="pb-1 md:py-2 md:my-2 col-span-6 sm:col-span-3">
                  <label
                    htmlFor="nombre"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Nombre
                  </label>
                  <input
                    type="text"
                    id="nombre"
                    {...register("nombre", { required: true })}
                    className="mt-1 focus:ring-sky-500 focus:border-sky-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
                <div className="pb-1 md:py-2 md:my-2 col-span-6 sm:col-span-3">
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium text-gray-700"
                  >
                    E-mail
                  </label>
                  <input
                    type="email"
                    id="email"
                    {...register("email", {
                      required: true,
                      pattern:
                        /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+.)+[^<>()[\].,;:\s@"]{2,})$/i,
                    })}
                    className="mt-1 focus:ring-sky-500 focus:border-sky-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
                <div className="pb-1 md:py-2 md:my-2 col-span-6 sm:col-span-3">
                  <label
                    htmlFor="telefono"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Teléfono
                  </label>
                  <input
                    type="text"
                    id="telefono"
                    {...register("telefono", { required: true })}
                    className="mt-1 focus:ring-sky-500 focus:border-sky-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
                <div className="pb-1 md:py-2 md:my-2 col-span-6 sm:col-span-3">
                  <label
                    htmlFor="empresa"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Empresa
                  </label>
                  <input
                    type="text"
                    id="empresa"
                    {...register("empresa")}
                    className="mt-1 focus:ring-sky-500 focus:border-sky-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
                <div className="col-span-6 sm:col-span-4">
                  <label
                    htmlFor="mensaje"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Mensaje
                  </label>
                  <div className="mt-1">
                    <textarea
                      id="mensaje"
                      name="mensaje"
                      rows={6}
                      {...register("mensaje", { required: true })}
                      className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md"
                    />
                  </div>
                  <p className="mt-2 text-sm text-gray-500">
                    Una breve descripción de sus requerimientos.
                  </p>
                </div>
              </div>
              <div className="py-2 my-2 text-right">
                <button
                  type="submit"
                  disabled={disabled}
                  className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-sky-600 hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500 disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none"
                >
                  Enviar mensaje
                </button>
              </div>
            </form>
          )}
        </div>
      </div>
    </div>
  );
};

export default Contacto;
